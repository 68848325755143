@use 'queryBuilder.common.scss';

div.dtsb-searchBuilder {
  div.dtsb-titleRow {
    height: 40px;

    div.dtsb-title {
      padding-top: 10px;
    }
  }
  div.dtsb-group {
    button.dtsb-clearGroup {
      margin-right: 8px;
    }
    div.dtsb-criteria {
      .form-control {
        width: auto;
        display: inline-block;
      }

      select.dtsb-condition {
        border-color: #28a745;
      }

      select.dtsb-data {
        border-color: #dc3545;
      }

      select.dtsb-value, input.dtsb-value {
        border-color: #007bff;
      }
    }

    div.dtsb-logicContainer {
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      margin-top: 10px;

      button.dtsb-logic {
        border: none;
        border-radius: 0px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis:3em;
        margin: 0px;
      }

      button.dtsb-clearGroup {
        border: none;
        border-radius: 0px;
        width: 2em;
        margin: 0px;
      }
    }
  }
}

div.dt-button-collection {
  div.dtsb-searchBuilder {
    padding-left: 10px;
    padding-right: 10px;
  }
}
