@use "sass:color";
@function dtb-tint( $color, $percent ) {
  @return color.mix(white, $color, $percent);
}

@function dtb-shade( $color, $percent ) {
  @return color.mix(black, $color, $percent);
}

$border-width: 1px;

@mixin dtb-two-stop-gradient($direction, $fromColor, $toColor) {
  background-color: $toColor; /* Fallback */
  background: linear-gradient($direction, $fromColor 0%, $toColor 100%);
}

div.dt-button-collection {
  overflow: visible !important;
  z-index: 2002 !important;

  div.dtsb-searchBuilder {
    box-sizing: border-box;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}

div.dt-button-collection.dtb-collection-closeable {
  div.dtsb-titleRow {
    padding-right: 40px;
  }
}

.dtsb-greyscale {
  border: $border-width solid #cecece !important;
}

div.dtsb-logicContainer {
  .dtsb-greyscale {
    border: none !important;
  }
}

div.dtsb-searchBuilder {
  justify-content: space-evenly;
  cursor: default;
  margin-bottom: 1em;
  text-align: left;
  width: 100%;

  button.dtsb-button,
  select {
    font-size: 1em;
  }

  div.dtsb-titleRow {
    justify-content: space-evenly;
    margin-bottom: 0.5em;

    div.dtsb-title {
      display: inline-block;
      padding-top: 14px;
    }

    div.dtsb-title:empty {
      display: inline;
    }

    button.dtsb-clearAll {
      float: right;
      margin-bottom: 0.8em;
    }
  }

  div.dtsb-vertical {
    .dtsb-value, .dtsb-data, .dtsb-condition {
      display: block;
    }
  }

  div.dtsb-group {
    position: relative;
    clear: both;
    margin-bottom: 0.8em;

    button.dtsb-search {
      float: right;
    }

    button.dtsb-clearGroup {
      margin: 2px;
      text-align: center;
      padding: 0;
    }

    div.dtsb-logicContainer {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      position: absolute;
      margin-top: 0.8em;
      margin-right: 0.8em;
    }

    div.dtsb-criteria {
      margin-bottom: 0.8em;
      display: flex;
      justify-content: start;
      flex-flow: row wrap;


      select.dtsb-dropDown,
      input.dtsb-input{
        padding: 0.4em;
        margin-right: 0.8em;
        min-width: 5em;
        max-width: 20em;
        color: inherit;
        font-size: 1em;

        option.dtsb-notItalic {
          font-style: normal;
        }
      }


      select.dtsb-italic{
        font-style: italic;
      }

      div.dtsb-inputCont {
        flex: 1;
        white-space: nowrap;
        span.dtsb-joiner {
          margin-right: 0.8em;
        }
        input.dtsb-value {
          width: 33%;
        }

        select,
        input {
          height: 100%;
          box-sizing: border-box;
        }
      }

      div.dtsb-buttonContainer {
        margin-left: auto;
        display: inline-block;

        button.dtsb-delete, button.dtsb-right, button.dtsb-left{
          margin-right: 0.8em;

          &:last-child {
            margin-right: 0;
          }
        }
      }

    }

    @media screen and (max-width: 550px) {
      div.dtsb-criteria {
        display: flex;
        flex-flow: none;
        flex-direction: column;
        justify-content: start;
        padding-right: calc(35px + 0.8em);
        margin-bottom: 0px;
        &:not(:first-child),
        &:not(:nth-child(2)),
        &:not(:last-child) {
          padding-top: 0.8em;
        }

        &:first-child,
        &:nth-child(2),
        &:last-child {
          padding-top: 0em;
        }


        select.dtsb-dropDown,
        input.dtsb-input {
          max-width: none;
          width: 100%;
          margin-bottom: 0.8em;
          margin-right: 0.8em;
        }

        div.dtsb-inputCont {
          margin-right: 0.8em;
        }

        div.dtsb-buttonContainer {
          position: absolute;
          width: 35px;
          display: flex;
          flex-wrap: wrap-reverse;
          right: 0;

          button {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}