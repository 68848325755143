@use "sass:meta";
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'bootstrap/dist/css/bootstrap.css';
@import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
@import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
@import 'datatables.net-select-bs4/css/select.bootstrap4.min.css';

@include meta.load-css('queryBuilder.bootstrap4.scss');

#card-words .page-link {
  font-size: 1.3em;
  line-height: 0.83em;
}

#card-tags .page-link {
  font-size: 1.3em;
  line-height: 0.83em;
}

#tags-search-result tbody tr:hover {
  background-color: #f0f0ff;
}

#words-search-result tbody tr:hover {
  background-color: #f0f0ff;
}

.select-tags-listbox-area {
  display: grid;
  padding: 20px;
  grid-gap: 2em;
  grid-template-columns: repeat(2, 1fr);
}

.select-tags-listbox-area .select-tags-listbox {
  min-width: 15em;
  min-height: 18em;
  margin: 1em 0 0;
  padding: 0;
}

.lx-checkbox-container {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin: auto;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.lx-checkbox-container input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.lx-checkbox-container .lx-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: transparent;
}

td:hover input ~ .lx-checkbox {
  background-color: #ccc;
}

.lx-checkbox-container input:checked ~ .lx-checkbox {
  background-color: #2196F3;
}

.lx-checkbox:after {
  position: absolute;
  display: none;
  content: "";
}

.lx-checkbox-container input:checked ~ .lx-checkbox:after {
  display: contents;
}

.lx-checkbox-container .lx-checkbox:after {
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  margin: auto;
  content: "\f00c";
  color: white;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.dt-scroll-head {
  overflow: visible !important;
}

.lx-dropdown-tag {
  font-size: 0.85rem !important;
  font-weight: bold !important;
  line-height: 0.5 !important;
  color: #858796 !important;
  border: none !important;
  background: none !important;
}

.lx-btn-letter {
  font-size: 0.85rem !important;
  line-height: 2rem !important;
  width: 2rem;
  height: 2rem;
  padding: unset !important;
  text-align: center !important;
}

.input-validation-error {
  border: solid 1px red !important;
}

div.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0.75em 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
  overflow: hidden;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

div.dtsb-inputCont {
  height: calc(1.5em + 0.75rem + 2px);
}